<template>
  <section class="back-button" @click="$emit('clicked')">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4759_1420)">
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4759_1420">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <span class="back-button__title">Назад</span>
  </section>
</template>

<script>
export default {
  name: 'TerminalBackButton',
}
</script>

<style lang="sass" scoped>
.back-button
  display: flex
  justify-content: center
  align-items: center
  gap: 8px
  width: 140px
  height: 56px
  border-radius: 50px
  font-size: 20px
  font-weight: 500
  background: $special
  color: $color-white
  cursor: pointer
  &:hover
    background: $hover
  &:active
    background: $visited

  &__title
    font-family: $fontPrimary
</style>
