var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "back-button",
      on: {
        click: function ($event) {
          return _vm.$emit("clicked")
        },
      },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("g", { attrs: { "clip-path": "url(#clip0_4759_1420)" } }, [
            _c("path", {
              attrs: {
                d: "M15.5 19L8.5 12L15.5 5",
                stroke: "white",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]),
          _c("defs", [
            _c("clipPath", { attrs: { id: "clip0_4759_1420" } }, [
              _c("rect", {
                attrs: { width: "24", height: "24", fill: "white" },
              }),
            ]),
          ]),
        ]
      ),
      _c("span", { staticClass: "back-button__title" }, [_vm._v("Назад")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }